@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.tooltip {
  $p: &;

  &__trigger {
    position: relative;

    &:hover {
      #{$p}__content {
        opacity: 1;
        transform: scale(1);
        pointer-events: auto;
      }
    }
  }

  &__content {
    position: fixed;
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 0.2s ease, transform 0.2s ease;
    pointer-events: none;
    z-index: 99;
    color: $purple;
    border-radius: 10px;
    font-size: $size14;
    width: calc(100dvw - 30px);

    &:hover {
      opacity: 1;
      transform: scale(1);
      pointer-events: auto;
    }
  }

  &__arrow {
    position: absolute;
    width: 18px;
    height: 18px;
    background: $gameCardBackground;
    border-radius: 3px;
  }
}
