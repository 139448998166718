@import "assets/styles/design-tokens/colors.module.scss";

.splash-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  z-index: 100;

  &__img {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  &__overlay {
    position: fixed;
    inset: 0;
    z-index: 101;
    @include apply-opacity(background-color, $black, 0.5);
    animation: splashOverlay 300ms ease-in-out 300ms forwards;
    opacity: 0;
  }

  &__loader {
    z-index: 102;
  }
}

@keyframes splashOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
