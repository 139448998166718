@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.sign-in-email {
  width: 540px;

  &--loading {
    display: none;
  }

  &__wrapper {
    font-size: $size14;
    display: flex;
    flex-direction: column;
  }

  &__description {
    margin-top: $spacing-xl;
    margin-bottom: $spacing-s;
  }

  &__forgot-password {
    text-align: center;
    margin: $spacing-xl $spacing-auto $spacing-50 $spacing-auto;
    display: block;
  }
}
