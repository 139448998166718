@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/spacing.scss";

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include apply-opacity(background-color, $purple, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  will-change: transform, opacity;
  background-color: $purple;
  transform: translate(50%, 50%);
  overflow: hidden;
  padding: $spacing-l;

  &__content {
    padding: 20px 0 0;
    position: relative;
  }
}
