@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.input-tooltip-container {
  .tooltip__content {
    padding: $spacing-xs $spacing-s;
    border-radius: 4px;
    background-color: $primary;
    font-size: $size12;
    color: $white;
    margin-right: $spacing-s;
    max-width: max-content;
  }
}
