@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/spacing.scss";

.sign-in-modal {
  width: 360px;

  &__phone-number-button {
    margin-top: $spacing-60;
  }

  &__warning-subtitle {
    margin: $spacing-l 0 $spacing-100 0;

    .warning-subtitle__icon {
      min-width: 20px;
      height: 20px;
      margin-right: $spacing-xs;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $spacing-l;
  }
}
