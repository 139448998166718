@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: $spacing-35;
  margin-bottom: $spacing-s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &__age-label {
    font-weight: bold;
  }

  &__terms-wrapper {
    font-size: $size14;
    color: $purple;
  }
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  background-color: $white;
  box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: $grey-300;
}

/* When the checkbox is checked, add a green background */
.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: $tertiary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Light checkbox styles
.checkbox-container--dark {
  .checkbox-container__terms-wrapper {
    color: $white;
  }
  .checkbox-checkmark {
    background-color: $gameCardProposal;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  &:hover input ~ .checkbox-checkmark {
    background-color: darken($gameCardProposal, 10%);
  }

  input:checked ~ .checkbox-checkmark {
    background-color: $tertiary;
  }
}
