@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.logo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: $spacing-l;

  &__description {
    font-size: $size16;
    color: $white;
    text-align: center;
    white-space: pre-wrap;
  }

  &__img {
    width: 100%;
    height: 120px;
    object-fit: contain;
  }
}
