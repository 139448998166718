@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.social-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  position: relative;
  border-radius: 10px;
  height: 50px;
  width: calc(100vw - 30px);

  .img {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 10px;
  }

  .title {
    font-weight: bold;
    font-size: $size14;
    color: $purple;
  }
}
