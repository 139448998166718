@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.modal-header {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;

  &__title {
    font-size: $size20;
    font-weight: bold;
    flex: 1;
    text-align: center;
  }

  &__button {
    background-color: $purple;
    cursor: pointer;
    border-radius: 100%;
    width: 34px;
    height: 34px;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($purple, 10);
      scale: 1.05;
    }
  }

  &__back-button {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__button-icon {
    font-size: $size14;
  }

  &__button-arrow-icon {
    font-size: $size22;
  }
}
