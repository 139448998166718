@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.masked-input {
  font-weight: bold;
  background-color: transparent;
  color: white;
  width: 100%;
  text-align: center;
  font-size: $size48;
  margin-bottom: $spacing-xxl;
  outline: none;
  &[disabled] {
    opacity: 1;
    color: $white;
  }

  &::placeholder {
    color: white;
    opacity: 1;
  }

  &--dark {
    background-color: $gameCardProposal;
    border-radius: 6px;
    text-align: left;
    padding-left: $spacing-s;
    font-size: $size16;
    font-weight: normal;
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    &::placeholder {
      color: $secondaryParagraph;
      opacity: 1;
    }

    &:hover,
    &:focus,
    &:active {
      outline: 1px solid $white;
    }

    transition: outline 0.3s;
  }

  &--disabled {
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
}
