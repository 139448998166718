@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.enter-code {
  width: 540px;
  height: 360px;

  &__description {
    font-size: $size14;
    margin-top: $spacing-s;
    margin-bottom: $spacing-xl;
  }

  &__time-wrapper {
    margin-top: $spacing-xl;
    font-size: $size14;
    text-align: center;
    margin-bottom: $spacing-s;
  }

  &__time-left {
    font-size: $size20;
    font-weight: bold;
  }

  &__send-new-code {
    color: $gold;
    font-size: $size14;
  }
}
