@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.date-container {
  display: flex;
  position: relative;
  align-items: center;
  align-self: center;
  width: 100%;
  padding-right: $spacing-s;
  background-color: $gameCardProposal;
  height: 42px;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(255, 255, 255, 0.1);

  &--invalid {
    border-color: $primary;
  }

  &__input {
    color: $white;
    background-color: $gameCardProposal;
    border-radius: 5px;
    outline-style: none;

    display: flex;
    flex: 1 1 0;
    width: 100%;
    height: 100%;
    font-size: $size16;
    padding: $spacing-s;

    &::placeholder {
      color: $secondaryParagraph;
      opacity: 1;
    }
  }
}
