$spacing-auto: auto;
$spacing-1: 1px;
$spacing-2: 2px;
$spacing-xs: 5px;
$spacing-s: 10px;
$spacing-m: 15px;
$spacing-l: 20px;
$spacing-xl: 25px;
$spacing-xxl: 30px;
$spacing-35: 35px;
$spacing-40: 40px;
$spacing-45: 45px;
$spacing-50: 50px;
$spacing-55: 55px;
$spacing-60: 60px;
$spacing-70: 70px;
$spacing-80: 80px;
$spacing-90: 90px;
$spacing-100: 100px;
$spacing-105: 105px;
$spacing-120: 120px;
$spacing-125: 125px;
$spacing-130: 130px;
$spacing-140: 140px;
$spacing-150: 150px;
$spacing-185: 185px;
$spacing-260: 260px;
