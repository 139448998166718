@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.fliff-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-shrink: 0;
  width: 100%;
  border-radius: 4px;
  height: 40px;
  z-index: 1;
  padding-left: $spacing-s;
  padding-right: $spacing-s;
  background-color: $gold;
  color: $purple;
  transition: background-color 0.3s;

  &__label {
    font-weight: bold;
    position: relative;
    font-size: $size14;
  }

  &:disabled {
    background-color: $secondaryParagraph;
    cursor: not-allowed;
  }

  &:hover {
    background-color: darken($gold, 10%);
  }

  &.fliff-button-disabled {
    pointer-events: none;
    background-color: $secondaryParagraph !important;
    cursor: not-allowed;

    &:hover {
      background-color: $secondaryParagraph !important;
    }
  }

  &--cash {
    background-color: $tertiary;
  }

  &--cash:hover:not(.fliff-button-disabled) {
    background-color: darken($tertiary, 10%);
  }

  &--secondary {
    @include apply-opacity(background-color, $white, 0.05);
    color: $white;
    transition: background-color 0.3s;

    &:hover:not(.fliff-button-disabled) {
      @include apply-opacity(background-color, $white, 0.1);
    }
  }
}
