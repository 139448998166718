@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.sign-in-phone {
  width: 540px;
  min-height: 340px;
  display: flex;
  flex-direction: column;

  &__description {
    font-size: $size14;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-40;
  }

  &__input--verified {
    border: 1px solid $tertiary;
  }

  &__button {
    margin-top: $spacing-auto;
  }
}
