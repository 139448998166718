@font-face {
  font-family: "fontello";
  src: url("./fontello.eot?81344990");
  src: url("./fontello.eot?81344990#iefix") format("embedded-opentype"),
    url("./fontello.woff2?81344990") format("woff2"),
    url("./fontello.woff?81344990") format("woff"),
    url("./fontello.ttf?81344990") format("truetype"),
    url("./fontello.svg?81344990#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?81344990#fontello') format('svg');
  }
}
*/
[class^="icon-"],
[class*=" icon-"] {
  line-height: 0;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  line-height: normal;
  text-transform: none;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-lock:before {
  content: "\e800";
} /* '' */
.icon-mma:before {
  content: "\e801";
} /* '' */
.icon-flag-empty:before {
  content: "\e802";
} /* '' */
.icon-score:before {
  content: "\e803";
} /* '' */
.icon-reward:before {
  content: "\e804";
} /* '' */
.icon-home:before {
  content: "\e805";
} /* '' */
.icon-live:before {
  content: "\e806";
} /* '' */
.icon-basketball:before {
  content: "\e807";
} /* '' */
.icon-nflball:before {
  content: "\e808";
} /* '' */
.icon-plus-full:before {
  content: "\e809";
} /* '' */
.icon-cancel:before {
  content: "\e80a";
} /* '' */
.icon-menu:before {
  content: "\e80b";
} /* '' */
.icon-poker:before {
  content: "\e80c";
} /* '' */
.icon-code:before {
  content: "\e80d";
} /* '' */
.icon-minus:before {
  content: "\e80e";
} /* '' */
.icon-plus:before {
  content: "\e80f";
} /* '' */
.icon-clock:before {
  content: "\e810";
} /* '' */
.icon-gamepad:before {
  content: "\e811";
} /* '' */
.icon-signin:before {
  content: "\e812";
} /* '' */
.icon-menu-small:before {
  content: "\e813";
} /* '' */
.icon-settings:before {
  content: "\e814";
} /* '' */
.icon-mail:before {
  content: "\e815";
} /* '' */
.icon-check:before {
  content: "\e816";
} /* '' */
.icon-rocket-1:before {
  content: "\e817";
} /* '' */
.icon-bank:before {
  content: "\e818";
} /* '' */
.icon-graph:before {
  content: "\e819";
} /* '' */
.icon-calendar:before {
  content: "\e81a";
} /* '' */
.icon-avatar:before {
  content: "\e81b";
} /* '' */
.icon-vs:before {
  content: "\e81c";
} /* '' */
.icon-bets:before {
  content: "\e81d";
} /* '' */
.icon-play:before {
  content: "\e81e";
} /* '' */
.icon-sports:before {
  content: "\e81f";
} /* '' */
.icon-close:before {
  content: "\e820";
} /* '' */
.icon-attention:before {
  content: "\e821";
} /* '' */
.icon-soccer:before {
  content: "\e822";
} /* '' */
.icon-ufc:before {
  content: "\e823";
} /* '' */
.icon-flag:before {
  content: "\e824";
} /* '' */
.icon-rocket:before {
  content: "\e825";
} /* '' */
.icon-nhlball:before {
  content: "\e826";
} /* '' */
.icon-olympics:before {
  content: "\e827";
} /* '' */
.icon-throphy:before {
  content: "\e828";
} /* '' */
.icon-boxing:before {
  content: "\e829";
} /* '' */
.icon-ball:before {
  content: "\e82a";
} /* '' */
.icon-baseball:before {
  content: "\e82b";
} /* '' */
.icon-cashier:before {
  content: "\e82c";
} /* '' */
.icon-cash:before {
  content: "\e82d";
} /* '' */
.icon-social:before {
  content: "\e82e";
} /* '' */
.icon-file:before {
  content: "\e82f";
} /* '' */
.icon-ico_minus:before {
  content: "\e830";
} /* '' */
.icon-ico_trophy:before {
  content: "\e831";
} /* '' */
.icon-invitefriends:before {
  content: "\e832";
} /* '' */
.icon-wallet:before {
  content: "\e833";
} /* '' */
.icon-ico_rewards:before {
  content: "\e834";
} /* '' */
.icon-copy:before {
  content: "\e835";
} /* '' */
.icon-ico_plus:before {
  content: "\e836";
} /* '' */
.icon-warning:before {
  content: "\e837";
} /* '' */
.icon-activity:before {
  content: "\e838";
} /* '' */
.icon-golf:before {
  content: "\e83e";
} /* '' */
.icon-horseracing:before {
  content: "\e83f";
} /* '' */
.icon-tabletennis:before {
  content: "\e840";
} /* '' */
.icon-tennis:before {
  content: "\e841";
} /* '' */
.icon-fliff-coin:before {
  content: "\e842";
} /* '' */
.icon-lacrosse:before {
  content: "\e843";
} /* '' */
.icon-xp:before {
  content: "\e844";
} /* '' */
.icon-warning-exclamation:before {
  content: "\e845";
} /* '' */
.icon-check-in-circle:before {
  content: "\e846";
} /* '' */
.icon-ico-calendar:before {
  content: "\e847";
} /* '' */
.icon-dotted-list:before {
  content: "\e848";
} /* '' */
.icon-superbowl:before {
  content: "\e84a";
} /* '' */
.icon-redeem-cash:before {
  content: "\e84c";
} /* '' */
.icon-idea:before {
  content: "\e84d";
} /* '' */
.icon-support:before {
  content: "\e84e";
} /* '' */
.icon-logout:before {
  content: "\e84f";
} /* '' */
.icon-friends:before {
  content: "\e850";
} /* '' */
.icon-deposit:before {
  content: "\e851";
} /* '' */
.icon-id:before {
  content: "\e852";
} /* '' */
.icon-prize-redemption:before {
  content: "\e853";
} /* '' */
.icon-caret-up-down:before {
  content: "\e854";
} /* '' */
.icon-ico-verified:before {
  content: "\e855";
} /* '' */
.icon-user:before {
  content: "\f061";
} /* '' */
.icon-info:before {
  content: "\f086";
} /* '' */
.icon-angle-left:before {
  content: "\f104";
} /* '' */
.icon-angle-right:before {
  content: "\f105";
} /* '' */
.icon-angle-up:before {
  content: "\f106";
} /* '' */
.icon-angle-down:before {
  content: "\f107";
} /* '' */
.icon-circle-thin:before {
  content: "\f1db";
} /* '' */
.icon-user-o:before {
  content: "\f2c0";
} /* '' */
