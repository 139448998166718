@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.button {
  border: none;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-s $spacing-l;
  text-align: center;
  width: 100%;

  &__content {
    z-index: 1;
    display: flex;
    align-items: center;
    gap: $spacing-xs;

    &--vertical {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &--big {
    height: 50px;
  }

  &--small {
    height: 35px;
    padding: 0 $spacing-m;
  }

  &--tiny {
    width: 60px;
    height: 25px;
  }

  &--primary {
    background: $primaryBtnGradient;
    border-bottom: 2px solid $grey-100;
  }

  &--secondary {
    background: $spaceCadet;
    border-bottom: 2px solid $whiteLightOpacity;
  }

  &--tertiary {
    background: $tertiaryBtnGradient;
  }

  &--gold {
    background: $goldBtnGradient;
  }

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &__label {
    font-weight: bold;
    font-size: $size14;
    line-height: 1;

    &--white {
      color: $white;
    }

    &--tertiary {
      color: $tertiary;
    }

    &--gold {
      color: $gold-label;
    }

    &--purple {
      color: $purple;
    }
  }
}
