@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.success-purchase-modal {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  top: 0;
  z-index: 100;
  @include apply-opacity(background-color, $purple, 0.8);
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-s;
  transition: all 0.3s ease-in-out;

  &__logo {
    margin-top: $spacing-m;
    width: 150px;
  }

  &__image {
    width: 220px;
  }

  &__entry {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-weight: bold;
    font-size: $size24;
    color: $gold;
    text-align: center;
    max-width: 250px;
    margin: 0 $spacing-auto;
  }

  &__background {
    position: relative;
  }

  &__glow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    z-index: -1;
  }
}
