@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.fliff-cash-box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: $spacing-xs;
  max-width: 270px;
  @include apply-opacity(background-color, $white, 0.1);

  &__icon {
    width: 40px;
    height: 40px;
    margin: 0 $spacing-s 0 0;
    transform: rotate(90deg);
  }

  &__amount {
    color: $tertiary;
    font-size: $size22;
    font-weight: bold;
    line-height: 1;
  }
}
