@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.sign-up-modal {
  width: 360px;

  &__description {
    padding-top: 0;
    margin-bottom: $spacing-xl;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $spacing-xl;
  }

  &__label {
    font-size: $size14;
  }
}
