@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.currencies-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 $spacing-xxl;
  width: 100%;

  &__wrapper {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__icon {
    width: 25px;
    height: 25px;
    margin: 0 $spacing-xs 0 0;

    &--cash {
      width: 12px;
    }
  }

  &__amount {
    font-size: $size20;
    font-weight: bold;
    color: $gold;
    display: flex;
    align-items: center;
    justify-content: center;

    &--cash {
      color: $tertiary;
    }
  }

  &__label {
    font-size: $size16;
    font-weight: bold;
    margin: $spacing-xs 0 0;
    color: $gold;

    &--cash {
      color: $tertiary;
    }
  }
}
