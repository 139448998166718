.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &--small {
    width: 20px;
    height: 20px;
  }

  &--medium {
    width: 50px;
    height: 50px;
  }

  &--big {
    width: 75px;
    height: 75px;
  }
}
