@import "assets/styles/design-tokens/colors.module.scss";

.loading-modal-component-transparent {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 1 1 0;
  background-color: transparent;
  z-index: 999;
}

.loading-modal-component {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  @include apply-opacity(background-color, $purple, 0.86);
}
