@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.forgot-password {
  width: 540px;

  &--loading {
    display: none;
  }

  &__description {
    font-size: $size14;
    margin-top: $spacing-l;
    margin-bottom: $spacing-l;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $size14;
    margin-top: $spacing-60;
    margin-bottom: $spacing-s;
  }

  &__label {
    font-size: $size14;
    margin-right: $spacing-xs;
  }
}
