@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.text-link {
  position: relative;
  text-decoration: none;
  color: $gold;
  font-size: $size14;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: $gold;
    transition: width 0.3s ease;
  }

  &:hover:after {
    width: 100%;
  }

  &--cash {
    color: $tertiary;
  }
  &--cash:after {
    background-color: $tertiary;
  }
}
