@import "assets/styles/design-tokens/spacing.scss";

.complete-profile {
  width: 400px;

  &__promo-info {
    background: none;
  }

  &__action-btn {
    margin-top: $spacing-xl;
  }

  &__checkbox {
    margin-top: $spacing-xl;
  }
}
