@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.exclamation-mark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  color: $white;
  font-size: $size16;
  background-color: $primary;
  cursor: pointer;

  &--small {
    width: 16px;
    height: 16px;
    font-size: $size12;
  }

  &--secondary {
    background-color: $orange;
  }

  &__label {
    text-align: center;
    vertical-align: center;
  }
}
