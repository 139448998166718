@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.input-container-regular {
  display: flex;
  position: relative;
  align-items: center;
  align-self: center;
  width: 100%;
  padding-right: $spacing-s;
  background-color: $white;
  height: 42px;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid $white;

  &--invalid {
    border-color: $primary;
  }

  &--disabled input {
    cursor: not-allowed;
  }

  input {
    display: flex;
    flex: 1 1 0;
    outline-style: none;
    width: 100%;
    height: 100%;
    color: $black;
    font-size: $size16;
    padding: $spacing-s;
    border-radius: 5px;
  }

  &--dark {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: $gameCardProposal;

    input {
      color: $white;
      background-color: $gameCardProposal;
    }
  }

  &--focused {
    border: 1px solid $white;
  }
}
