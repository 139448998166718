@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.confirmation-modal {
  background-color: $white;
  font-size: $size14;
  color: $black;
  border-radius: 8px;
  padding: $spacing-s;

  &__title {
    &--bold {
      font-weight: 700;
    }

    &--mb-2 {
      margin-bottom: $spacing-s;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: $spacing-m 0 0;
    gap: $spacing-s;
  }

  &__button {
    width: 49%;
  }
}
