$transparent: transparent;

$purple-hover: rgba(61, 63, 129, 0.6);
$purple-opacity: rgba(21, 21, 68, 0.1);
$light-purple: #2d2d57;
$purple-50: rgb(160, 71, 251);
$purple-100: #575795;
$purple-200: rgb(160, 71, 251);
$purple-500: #3e417d;
$purple-700: #252570;
$purple-750: #1d2350;
$purple-800: #1a1a5d;
$purple: #151544;

$cyan: #64effd;
$neutralBlue: #0099ff;
$neutralBlue-100: #0c90ea;
$neutralBlue-200: #137dc6;
$neutralBlue-300: #6bbbfc;

$tertiary-100: #98ea9b;
$tertiary: #28e646;
$special-tertiary: #008615;
$tertiary-hover: #22ce3d;
$darkTertiary: #209245;

$gold-100: rgb(255, 205, 61);
$gold: #ffcd3d;
$darkGold: #a18340;
$special-gold: #946e00;
$light-gold: #e9bb3f;
$special-border-gold: #c96f00;
$gold-gradient: linear-gradient(to right, #ffda45 0%, #ffa300 100%);
$gold-label: #e3a933;

$orange-100: #efc1b5;
$orange-200: #ffcc00;
$orange-300: #e06a48;
$orange: #f2ac5a;

$black: #000000;
$black-500: rgba(0, 0, 0, 0.5);
$darkBlue: #00143c;
$primary: #ff233e;
$darkGrey: #28325a;
$darkestGrey: #5f738c;
$accentGrey: #475569;
$grey-100: #8f9daf;
$grey-200: #eff1f3;
$grey-300: #ccc;
$grey-500: #ffffff24;
$secondaryParagraph: #94a3b8;
$lightGrey: #d7d7e6;
$opacityGrey: rgba(71, 85, 105, 0.12);
$lightOpacityGrey: rgba(228, 232, 238, 0.4);
$lineColor: #dee5ea;
$white: #ffffff;
$whiteLightOpacity: rgba(255, 255, 255, 0.08);

$betslipPrice: #06243b;
$gameCardBackground: #22276d;
$gameCardFooter: #2f3479;
$gameCardNote: #1c1a54;
$gameCardProposal: #3b3b7f;
$cultured: #f8f8f8;
$brightGray: #e3e7ef;
$darkYellow: #5f4a00;
$pink-100: rgb(255, 0, 255);
$pink: #6a00a7;
$spaceCadet: #282851;

$primaryBtnGradient: linear-gradient(180deg, #1f35ff, #7e00ff);
$tertiaryBtnGradient: linear-gradient(
  180deg,
  #2ee64b,
  #2ee64b,
  #57eb6f,
  #fdffe7
);
$goldBtnGradient: linear-gradient(180deg, #e3a933, #fceebb);
$cardGradient: linear-gradient(
  180deg,
  rgba($purple-750, 1),
  rgba($purple-50, 0.3)
);

@mixin apply-opacity($property, $baseColor, $opacity, $important: false) {
  @if $baseColor == null {
    @error "Base color must be provided!";
  }

  @if $opacity == null {
    @error "Opacity must be provided!";
  }

  $color: rgba(red($baseColor), green($baseColor), blue($baseColor), $opacity);

  @if $important == true {
    #{$property}: $color !important;
  } @else {
    #{$property}: $color;
  }
}

@mixin border($borderType, $borderWidth, $baseColor, $opacity) {
  $color: null;

  @if $baseColor == null {
    @error "Base color must be provided!";
  }

  $color: rgba(red($baseColor), green($baseColor), blue($baseColor), $opacity);

  #{$borderType}: $borderWidth solid $color;
}

:export {
  transparentColor: $transparent;
  gameCardBackground: $gameCardBackground;
  purpleColor: $purple;
  purpleOpacity: $purple-opacity;
  gameCardFooterColor: $gameCardFooter;
  neutralBlueColor: $neutralBlue;
  whiteColor: $white;
  whiteLightOpacityColor: $whiteLightOpacity;
  tertiaryColor: $tertiary;
  goldColor: $gold;
  primaryColor: $primary;
  secondaryParagraphColor: $secondaryParagraph;
  accentGreyColor: $accentGrey;
  darkGold: $darkGold;
  darkTertiary: $darkTertiary;
  darkGreyColor: $darkGrey;
  orangeColor: $orange;
  blackOpacity: $black-500;
  lightGreyColor: $lightGrey;
  gameCardProposal: $gameCardProposal;
  spaceCadet: $spaceCadet;
}
