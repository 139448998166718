@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";
@import "assets/styles/design-tokens/reset.scss";

@import url("assets/fonts/stylesheet.css");
@import url("assets/fonts/fontello.css");
@import "rc-slider/assets/index.css";
@import "react-loading-skeleton/dist/skeleton.css";

[class^="icon-"]:before,
[class*=" icon-"]:before {
  display: inline-table;
}

:root {
  --tab-bar-height: calc(65px + env(safe-area-inset-bottom));
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  background-color: $purple;
  color: $white;
  max-width: 1440px;
  width: 100%;
  align-self: normal;
  -webkit-tap-highlight-color: transparent;
}

body.fliff-desktop {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  margin: 0 $spacing-auto;
  width: 100%;
  height: 100vh;

  overflow: auto;

  ::selection {
    color: $purple;
    background: $white;
  }
}

#root.desktop-root {
  overflow: unset;
  max-width: initial;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll-bars::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll-bars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// disable pull-to-refresh on mobile browsers
html {
  overscroll-behavior: none;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  scrollbar-color: $purple transparent;
}

body {
  background-color: $purple;
  color: $white;
  overscroll-behavior-y: contain;
  font-family: $font-family-avant;
}

.btn {
  padding: $spacing-s $spacing-xl;
  border-radius: $spacing-s;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
