$size5: 5px;
$size7: 7px;
$size8: 8px;
$size9: 9px;
$size10: 10px;
$size11: 11px;
$size12: 12px;
$size13: 13px;
$size14: 14px;
$size15: 15px;
$size16: 16px;
$size18: 18px;
$size19: 19px;
$size20: 20px;
$size22: 22px;
$size24: 24px;
$size25: 25px;
$size26: 26px;
$size28: 28px;
$size30: 30px;
$size32: 32px;
$size34: 34px;
$size35: 35px;
$size36: 36px;
$size38: 38px;
$size40: 40px;
$size44: 44px;
$size45: 45px;
$size46: 46px;
$size48: 48px;
$size50: 50px;
$size55: 55px;
$size56: 56px;
$size60: 60px;
$size67: 67px;
$size75: 75px;
$size90: 90px;
$size150: 150px;

$font-family-avant: "Avantt";
$font-family-tungsten: "Tungsten";
