@import "assets/styles/design-tokens/colors.module.scss";

.green-checkmark-container {
  background-color: $tertiary;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 11px;
}
