@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.sign-up-promo {
  $animation-duration: 1s;
  $chest-move-distance: 10px;

  @keyframes chestAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-$chest-move-distance);
    }
  }

  @keyframes shadowAnimation {
    0% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  background-color: $purple;
  position: relative;
  background-image: url("../../../../assets/img/promo/promo-background.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &__lottie {
    position: absolute;
    top: -60px;
    width: 100%;
    z-index: 0;
  }

  &__content-wrapper {
    margin: $spacing-45 $spacing-70;
    text-align: center;
    color: $white;
    z-index: 2;

    img {
      display: inline-block;
    }
  }

  &__title {
    font-size: $size24;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: $spacing-s;
  }

  &__promo-text {
    font-size: $size18;
  }

  &__5x {
    font-weight: bold;
  }

  &__coins-text {
    color: $gold;
    font-weight: bold;
  }

  &__cash-text {
    color: $tertiary;
    font-weight: bold;
  }

  &__animated-chest {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__chest-img {
    width: 200px;
    animation: chestAnimation $animation-duration infinite alternate;
  }

  &__shadow {
    animation: shadowAnimation $animation-duration infinite alternate;
    margin-top: $spacing-xl;
  }
}
