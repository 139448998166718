@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.input-container-text-area {
  background-color: $white;
  display: flex;
  align-items: center;
  align-self: center;
  height: 200px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  position: relative;
  padding-right: $spacing-s;
  border: 1px solid $lineColor;

  textarea {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1 1 0;
    font-size: $size16;
    color: $black;
    outline: none;
    resize: none;
    padding: $spacing-s;
  }

  &--dark {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: $gameCardProposal;

    textarea {
      color: $white;
      background-color: $gameCardProposal;
    }
  }
}

.input-container-text-area-invalid {
  border-color: $primary;
}
