@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.social-buttons {
  &__transparent {
    width: 100%;
    background-color: $gameCardProposal;
    margin-bottom: $spacing-s;

    &:hover {
      background-color: darken($gameCardProposal, 10%);
    }

    .title {
      color: $white;
      font-weight: bold;
    }
  }
}
