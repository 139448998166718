@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.base-overlay-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include apply-opacity(background-color, $black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;

  &__content {
    width: 100%;
    margin: 0 $spacing-m;
  }
}
