@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.toast-notification {
  position: absolute;
  top: 70px;
  right: 18px;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  gap: $spacing-s;

  &__toast {
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    padding: $spacing-s;
    width: 300px;
    display: flex;
    flex-direction: column;
    color: $purple;
    background-color: white;

    &--error {
      @include border(border-left, 0.5px, $orange-300, 1);
      background-color: $orange-100;
    }

    &--info {
      @include border(border-left, 0.5px, $neutralBlue-200, 1);
      background-color: $neutralBlue-300;
    }

    &--success {
      @include border(border-left, 0.5px, $darkTertiary, 1);
      background-color: $tertiary-100;
    }
  }

  &__toast-content {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: bold;
    font-size: $size18;
  }

  &__close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  &__message {
    margin: 0.5rem 0 0;
    font-size: $size14;
  }
}
