@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.warning-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__subtitle {
    font-size: $size12;
    color: $secondaryParagraph;
    margin: $spacing-xs 0 0 $spacing-xs;
  }

  &__icon {
    min-width: 15px;
    width: 15px;
    height: 15px;
    margin-top: $spacing-xs;
    stroke: $secondaryParagraph;
  }
}
