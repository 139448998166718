@font-face {
  font-family: "Tungsten";
  src: url("Tungsten-SemiBold.woff2") format("woff2"),
    url("Tungsten-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tungsten";
  src: url("Tungsten-Bold.woff2") format("woff2"),
    url("Tungsten-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avantt";
  src: url("Avantt-Medium.woff2") format("woff2"),
    url("Avantt-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avantt";
  src: url("Avantt-Bold.woff2") format("woff2"),
    url("Avantt-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
