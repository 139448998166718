@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.date-pick-container {
  position: relative;

  .my-picks-calendar-container-mobile {
    background-color: $white;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    height: 0;
    top: 50px;
    overflow: hidden;

    &.my-picks-calendar-container-mobile-open {
      height: auto;
      position: absolute;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
  .input-container-regular {
    &--invalid {
      border-color: $primary;
    }

    > span {
      width: 100%;
      height: 100%;
      display: flex;
      flex: 1 1 0;
      color: $black;
      font-size: $size16;
      padding: $spacing-s;
      outline: none;

      &.date-input-placeholder {
        color: $secondaryParagraph;
      }
    }
  }
}
