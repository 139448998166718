@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.fliff-button-outline-container {
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  height: 40px;
  border-width: 1px;
  border-style: solid;

  &:hover {
    opacity: 0.7;
  }
}
.fliff-button-outline-disabled {
  background-color: $secondaryParagraph;
  pointer-events: none;
}
.fliff-button-outline-label {
  font-weight: bold;
  font-size: $size14;
}
