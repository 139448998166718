@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.select-list {
  $p: &;
  position: relative;

  &--invalid {
    .input-tooltip-container {
      position: absolute;
      right: 10px;
      top: 6px;
    }

    #{$p}__input {
      &__indicators {
        display: none;
      }

      &__control {
        border-color: $primary !important;
      }
    }
  }

  &--dark {
    #{$p}__input {
      &__single-value {
        color: $white !important;
      }

      &__control {
        @include apply-opacity(border-color, $white, 0.1, true);
        background-color: $gameCardProposal;
      }
    }

    input {
      color: $white;
      background-color: $gameCardProposal;
    }
  }

  .select-list__input {
    &__single-value {
      font-size: $size16;
      color: $black;
    }

    &__control {
      border-color: $lineColor;
      height: 42px;
      border-width: 1px;
      border-radius: 5px;
      box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.16);
      padding-right: $spacing-xs;
      cursor: pointer;

      &:hover {
        border-color: $lineColor;
      }
    }

    &__placeholder {
      font-size: $size16;
      color: $secondaryParagraph;
    }

    &__option {
      font-size: $size16;
      color: $black;
      background-color: white;
      cursor: pointer;
    }
  }

  .option-item-custom {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin-right: $spacing-s;
    }
  }

  .green-checkmark-container {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }

  &__input__menu {
    z-index: 2 !important;
  }
}
