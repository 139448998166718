@import "assets/styles/design-tokens/colors.module.scss";
@import "assets/styles/design-tokens/fonts.scss";
@import "assets/styles/design-tokens/spacing.scss";

.sign-up-with-email {
  width: 540px;

  &__description {
    font-size: $size14;
    margin-top: $spacing-l;
    margin-bottom: $spacing-s;
  }

  &__checkbox {
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl !important;
  }
}
